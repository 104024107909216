import React from "react";
import { ga } from "../lib/analytics";

// Styles
import "./footer.scss";

const Footer = () => {
  return (
    <div className="footerContainer">
      <div className="leftContainer">
        <img src="/logo.svg" alt="logo" className="logoImg" />
        <a href="https://auxp.com.au" target="_blank" rel="noreferrer">
          by XP Australia
        </a>
      </div>
      <div className="socialIconsContainer">
        <a
          href="https://portal.ridable.com.au"
          target="_blank"
          rel="noopener noreferrer"
          className="portalLink"
          onClick={() => {
            ga.event({
              category: "Portal",
              action: "Click on Portal Link",
              label: "Portal Ridable",
            });
          }}
        >
          Bikeshop Portal
        </a>
        <a
          href="https://facebook.com/ridableau"
          target="_blank"
          rel="noopener noreferrer"
          className="socialLogoImg"
          onClick={() => {
            ga.event({
              category: "Social",
              action: "Click on Social Icon",
              label: "Facebook Ridable",
            });
          }}
        >
          <img src="/facebook-icon.svg" alt="logo facebook" />
        </a>
        <a
          href="https://instagram.com/ridableau"
          target="_blank"
          rel="noopener noreferrer"
          className="socialLogoImg"
          onClick={() => {
            ga.event({
              category: "Social",
              action: "Click on Social Icon",
              label: "Instagram Ridable",
            });
          }}
        >
          <img src="/instagram-icon.svg" alt="logo" />
        </a>
        <a
          href="mailto:hello@ridable.com.au"
          target="_blank"
          rel="noopener noreferrer"
          className="socialLogoImg"
          onClick={() => {
            ga.event({
              category: "Social",
              action: "Click on Social Icon",
              label: "Email Ridable",
            });
          }}
        >
          <img src="/email-icon.svg" alt="logo" />
        </a>
      </div>
    </div>
  );
};

export default Footer;
