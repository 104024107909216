import gql from 'graphql-tag';

export default gql`
    query GetClients {
        getClients {
            _id
            name
            logo
            website
            location {
                name
                phone
            }
        }
    }
`;
