import React, { useState } from 'react';
import _ from 'lodash';
import { useMutation, useQuery } from '@apollo/react-hooks';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import CheckIcon from '@material-ui/icons/CheckCircleOutline';
import { ga } from '../lib/analytics';
import { utils } from '../lib/utils';

// External components
import FormSection from './formSection';
import ContentSection from '../components/contentSection';
import Footer from '../components/footer';
import BikeSection from './bikesSection';
import PartnersSection from './partnersSection';

// Queries
import getBikeBrands from '../data/queries/bike_brands/getBikeBrands';

// Mutations
import createMessageMutation from '../data/mutations/createMessage';

// Styles
import './homepage.scss';

function Homepage() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [openDialogFeedback, setOpenDialogFeedback] = useState(false);
    const [bikeData, setBikeData] = useState();

    const bikeBrandsResponse = useQuery(getBikeBrands);
    const [createMessage] = useMutation(createMessageMutation);

    const onSubmitContactForm = async (event) => {
        if (event) event.preventDefault();
        setLoading(true);

        // Creates the message in our database
        await createMessage({
            variables: {
                message: {
                    message,
                    type: 0,
                    context: {
                        email,
                        name
                    }
                }
            }
        });

        // Open feedback dialog
        setOpenDialogFeedback(true);
        resetState();
    };

    const onSubmitBikeForm = (params) => {
        // Sets the bike that that will render the bikes container
        setBikeData({
            type: params.type,
            gender: params.gender,
            height: params.height,
            category: params.category,
            avgUsage: params.avgUsage,
            priceRange: params.priceRange,
            email: params.email
        });
    };

    const resetState = () => {
        setName('');
        setEmail('');
        setMessage('');
        setLoading(false);
    };

    const renderBikeBrand = (brand) => {
        const link = utils.getLink({ website: _.get(brand, 'website'), campaign: 'homepage' });
        return (
            <div
                key={_.get(brand, '_id')}
                className={`brandLogo logo_${_.get(brand, 'name')}`}
            >
                <a
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                        ga.event({
                            category: 'Homepage',
                            action: 'Click on bike brand',
                            label: _.get(brand, 'name')
                        });
                    }}
                >
                    <img
                        src={_.get(brand, 'logo')}
                        alt={`Brand ${_.get(brand, 'name')}`}
                    />
                </a>
            </div>
        );
    };

    return (
        <div className="homepageContainer">
            <header className="header">
                <div className="contentContainer">
                    <a
                        href="https://ridable.com.au"
                    >
                        <img
                            src="/logo.svg"
                            alt="logo"
                            className="logoImg"
                        />
                    </a>
                    <div className="content">
                        <h1>Your best e-ride choice in just a few seconds</h1>
                        <p>Smart. Simple. Effective. <br />Ridable finds you the e-ride you need without all the noise you don&apos;t.</p>
                    </div>
                </div>
                <div className="headerImage" />
            </header>
            {bikeData ? (
                <>
                    <BikeSection
                        data={bikeData}
                        onClickNewSearch={() => setBikeData(null)}
                    />
                    <PartnersSection />
                </>
            ) : (
                <FormSection
                    onSubmit={onSubmitBikeForm}
                />
            )}
            <ContentSection
                title={<span>Find the best <br />e-ride for you</span>}
                content="We know how confusing and overwhelming choosing an electric bike or scooter can be. That's why Ridable breaks it down to the basics and simplifies this process for you. Whether the ride your looking for is an e-bike or an e-scooter, there’s no need to worry, we've got you!"
                image="/img_section1.svg"
                contentPosition="left"
                className="section1"
            />
            <ContentSection
                title="Worker, commuter or weekender?"
                content="Looking for a reliable e-ride for work? Do you need an e-ride for your everyday commute? Or for a weekend ride? Check out which e-ride is the best choice for you."
                image="/img_section2.svg"
                className="section2"
                contentPosition="right"
            />
            <ContentSection
                title="Ridable robots explained"
                content={<span>We created an equation 🔣 that brings riders the best possible e-ride choices in a consistent way every time. Our algorithm uses gender, height and daily average usage. Ok, if you really insist in seeing the equation, here it goes: <br />🚻 + ↕️ + ⏰🚴= 🤖🤓💭 = 👌🚲</span>}
                image="/img_section3.svg"
                className="section3"
                contentPosition="left"
            />
            <div className="bikeBrandLogos contentSection">
                <h3>e-ride brands</h3>
                <div className="brandsContainer">
                    {_.get(bikeBrandsResponse, 'loading') && <p>Loading...</p>}
                    {_.map(_.get(bikeBrandsResponse, 'data.getBikeBrands'), renderBikeBrand)}
                </div>
            </div>
            <div className="appIconContainer">
                <img src="/logo_icon.svg" alt="Logo Icon" />
            </div>
            <div className="contactFormContainer">
                <p>Hi there 👋. Wanna chat?</p>
                <h3>Contact</h3>
                <form
                    className="contactForm"
                    onSubmit={onSubmitContactForm}
                >
                    <div className="personalDetailsRow">
                        <TextField
                            id="name"
                            label="Name"
                            type="text"
                            className="nameField"
                            value={name}
                            required
                            onChange={(value) => setName(value.target.value)}
                        />
                        <TextField
                            id="email"
                            label="E-mail"
                            type="email"
                            className="emailField"
                            value={email}
                            required
                            onChange={(value) => setEmail(value.target.value)}
                        />
                    </div>
                    <div className="messageContainer">
                        <TextField
                            id="message"
                            label="Type here your message..."
                            type="text"
                            className="messageField"
                            multiline
                            rowsMax={6}
                            value={message}
                            required
                            onChange={(value) => setMessage(value.target.value)}
                        />
                    </div>
                    <Button
                        variant="outlined"
                        type="submit"
                        className="btnSend"
                        disabled={loading}
                    >
                        Send
                    </Button>
                </form>
            </div>
            <Footer />
            <Dialog
                open={openDialogFeedback}
                keepMounted
                onClose={() => setOpenDialogFeedback(false)}
                className="dialogFeedbackMessage"
                maxWidth="xs"
            >
                <CheckIcon
                    style={{ color: '#00FFB7', width: 68, height: 68 }}
                />
                <div className="contentContainer">
                    <h4>Howdy 🤓</h4>
                    <p>We have received your message and will be in touch soon</p>
                </div>
                <Button
                    variant="contained"
                    style={{
                        borderRadius: 30,
                        padding: '10px 35px',
                        textTransform: 'none',
                        background: 'linear-gradient(15deg, rgba(0,255,100,1) 0%, rgba(0,255,181,1) 70%)',
                        border: 0,
                        color: 'white',
                        fontWeight: 600,
                        height: 41,
                        minWidth: 140
                    }}
                    onClick={() => setOpenDialogFeedback(false)}
                >
                    Ok
                </Button>
            </Dialog>
        </div>
    );
}

export default Homepage;
