import React from 'react';
import _ from 'lodash';

const scooterUtils = {
    renderRangeRow(bike) {
        return (
            <>
                <strong>Range:</strong>&nbsp;<span>Up to {scooterUtils.getRangeDescription(_.get(bike, 'general_specs.max_range'))}</span>
            </>
        );
    },
    getPriceRangeDescriptionBasedOnAvgAmount(avg_amount) {
        if (avg_amount < 1000) return 'Basic';
        if (avg_amount >= 1000 && avg_amount < 2000) return 'Intermediate';
        if (avg_amount > 2000) return 'Premium';
        return '';
    },
    getScooterDescriptionHtml(ride) {
        return (
            <div className="descriptionContainer">
                <div className="descriptionRow">
                    <strong>Brand:</strong>&nbsp;<span>{_.get(ride, 'Brand.name')}</span>
                </div>
                <div className="descriptionRow">
                    {scooterUtils.renderRangeRow(ride)}
                </div>
                <div className="descriptionRow">
                    <strong>Price range:</strong>&nbsp;<span>{_.get(ride, 'calculatedPriceRangeDescription')}</span>
                </div>
            </div>
        );
    },
    getMaxSpeedDescription(maxSpeed) {
        if (!maxSpeed) return '-';
        return `${maxSpeed}km/h`;
    },
    getRangeDescription(range) {
        if (!range) return '-';
        return `${range}km`;
    },
    getLoadCapacityDescription(capacity) {
        if (!capacity) return '-';
        return `Max ${capacity}kg`;
    },
    getBikeWeightDescription(weight) {
        if (!weight) return '-';
        return `${weight}kg`;
    },
    getFieldData(data, field) {
        const bikeSizeFields = scooterUtils.getBikeSizesField(data);
        switch (field) {
            case 'brand':
                return _.get(data, 'Brand.name');
            case 'general_specs.max_speed':
                return scooterUtils.getMaxSpeedDescription(_.get(data, field));
            case 'general_specs.max_range':
                return scooterUtils.getRangeDescription(_.get(data, field));
            case 'general_specs.load_capacity':
                return scooterUtils.getLoadCapacityDescription(_.get(data, field));
            case 'weight':
                return scooterUtils.getBikeWeightDescription(_.get(bikeSizeFields, field));
            default:
                const defaultData = _.get(data, field);
                if (!defaultData) return '-';
                return defaultData;
        }
    },
    getData(dataset, field, field_2) {
        return _.map(dataset, (item) => {
            if (field_2) {
                return `${scooterUtils.getFieldData(item, field) || 0} - ${scooterUtils.getFieldData(item, field_2)}`;
            }
            return scooterUtils.getFieldData(item, field);
        });
    }
};

export { scooterUtils };
