const globalVariables = {
  getEnvironmentVariables() {
    const envVariables = {};
    const hostname = window.location.hostname.replace(
      /^([^-]+)-([^.-]*)\.app/,
      "$1.app"
    ); // Generalize hyphenated subdomains to their prefix
    switch (hostname) {
      case "localhost":
        envVariables.graphqlURI = "http://localhost:9000/graphql";
        envVariables.subscriptionURI = "ws://localhost:9000/graphql";
        envVariables.environment = "DEV";
        break;
      case "ridable.com.au":
        envVariables.graphqlURI = "https://graphql.ridable.com.au/graphql";
        envVariables.subscriptionURI = "wss://graphql.ridable.com.au/graphql";
        envVariables.environment = "PROD";
        break;
      default:
        envVariables.graphqlURI = "https://graphql.ridable.com.au/graphql";
        envVariables.subscriptionURI = "wss://graphql.ridable.com.au/graphql";
        envVariables.environment = "PROD";
        break;
    }
    return envVariables;
  },
};

export { globalVariables };
