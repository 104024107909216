import React, { useState } from 'react';
import _ from 'lodash';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { bikeUtils } from '../lib/bikes';
import { ga } from '../lib/analytics';

// Styles
import './formSection.scss';

const FormSection = (props) => {
    const [gender, setGender] = useState();
    const [height, setHeight] = useState();
    const [category, setCategory] = useState();
    const [avgUsage, setAvgUsage] = useState();
    const [priceRange, setPriceRange] = useState();
    const [email, setEmail] = useState();
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState(0);

    const heightOptions = [<option key="none" aria-label="None" value="" />];
    for (let r = 140; r <= 200; r += 1) {
        heightOptions.push(<option key={r} value={r}>{r}cm</option>);
    }

    const onSubmitForm = (event) => {
        if (event) event.preventDefault();
        setLoading(true);
        props.onSubmit({
            type,
            gender,
            height,
            category,
            avgUsage,
            priceRange,
            email
        });
        setLoading(false);

        ga.event({
            category: 'Homepage',
            action: 'Submitted details to find best e-ride',
            label: `Searched ${bikeUtils.getCategoryDescription(parseInt(category, 10))}`
        });
    };

    const TabPanel = ({ children, value, index }) => {
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`wrapped-tabpanel-${index}`}
                aria-labelledby={`wrapped-tab-${index}`}
            >
                {value === index && children}
            </div>
        );
    };

    return (
        <div id="bikeFormSection" className="formSection section">
            <img src="/green-logo.png" alt="logo" className="logoBg" />
            <form
                className="eBikeForm"
                onSubmit={onSubmitForm}
            >
                <p className="headingTitle">
                    <strong>Find your best</strong><br />e-ride here
                </p>
                <Tabs
                    value={type}
                    onChange={(event, newValue) => setType(newValue)}
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary"
                >
                    <Tab
                        label="e-bike"
                        icon={(
                            <img
                                src="/electricBikeIcon.svg"
                                alt="Electric Bike Icon"
                                className="electricBikeImg"
                            />
                        )}
                    />
                    <Tab
                        label="e-scooter"
                        icon={(
                            <img
                                src="/electricScooterIcon.svg"
                                alt="Electric Scooter Icon"
                                className="electricBikeImg"
                            />
                        )}
                    />
                </Tabs>
                <TabPanel value={type} index={0}>
                    <FormControl variant="filled" className="mtop30">
                        <InputLabel htmlFor="filled-gender" className={gender ? 'invisible' : ''}>Gender</InputLabel>
                        <Select
                            native
                            value={gender}
                            required
                            onChange={(value) => setGender(value.target.value)}
                            inputProps={{
                                name: 'gender',
                                id: 'filled-gender',
                            }}
                        >
                            <option aria-label="None" value="" />
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                        </Select>
                    </FormControl>
                    <FormControl variant="filled">
                        <InputLabel htmlFor="filled-height" className={height ? 'invisible' : ''}>Height</InputLabel>
                        <Select
                            native
                            value={height}
                            required
                            onChange={(value) => setHeight(value.target.value)}
                            inputProps={{
                                name: 'height',
                                id: 'filled-gender',
                            }}
                        >
                            {_.map(heightOptions, (item) => item)}
                        </Select>
                    </FormControl>
                    <FormControl variant="filled">
                        <InputLabel htmlFor="filled-category" className={category ? 'invisible' : ''}>Category</InputLabel>
                        <Select
                            native
                            value={category}
                            required
                            onChange={(value) => setCategory(value.target.value)}
                            inputProps={{
                                name: 'category',
                                id: 'filled-category',
                            }}
                        >
                            <option aria-label="None" value="" />
                            <option value={0}>Mountain bike</option>
                            <option value={1}>Urban / City bike</option>
                            <option value={2}>Trekking / Hybrid bike</option>
                        </Select>
                    </FormControl>
                </TabPanel>
                <FormControl variant="filled" className={type === 1 ? 'mtop30' : ''}>
                    <InputLabel htmlFor="filled-usage" className={avgUsage ? 'invisible' : ''}>Daily average usage</InputLabel>
                    <Select
                        native
                        value={avgUsage}
                        required
                        onChange={(value) => setAvgUsage(value.target.value)}
                        inputProps={{
                            name: 'avgUsage',
                            id: 'filled-avgUsage',
                        }}
                    >
                        <option aria-label="None" value="" />
                        <option value={45}>From 30min to 1hr</option>
                        <option value={90}>From 1hr to 2hrs</option>
                        <option value={180}>From 2hrs to 4hrs</option>
                        <option value={300}>From 4hrs to 6hrs</option>
                        <option value={420}>From 6hrs to 8hrs</option>
                    </Select>
                </FormControl>
                <FormControl variant="filled">
                    <InputLabel htmlFor="filled-usage" className={priceRange ? 'invisible' : ''}>Price range (optional)</InputLabel>
                    <Select
                        native
                        value={priceRange}
                        required={false}
                        onChange={(value) => setPriceRange(value.target.value)}
                        inputProps={{
                            name: 'priceRange',
                            id: 'filled-priceRange',
                        }}
                    >
                        <option aria-label="None" value="" />
                        {/* <option value="any">Any price range</option> */}
                        <option value="basic">Basic</option>
                        <option value="intermediate">Intermediate</option>
                        <option value="premium">Premium</option>
                    </Select>
                </FormControl>
                <TextField
                    id="email"
                    placeholder="Email"
                    type="email"
                    variant="outlined"
                    fullWidth
                    value={email}
                    required
                    onChange={(value) => setEmail(value.target.value)}
                    style={{ backgroundColor: '#fff', borderRadius: 4 }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className="filledButton"
                    style={{ borderRadius: 30, padding: '10px 35px', textTransform: 'none' }}
                    type="submit"
                >
                    {loading ? 'Loading...' : 'Leeeeeets go'}
                </Button>
            </form>
        </div>
    );
};

export default FormSection;
