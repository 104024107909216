import React from 'react';
import { hydrate, render } from 'react-dom';
import { createBrowserHistory } from 'history';
import fetch from 'cross-fetch';
import { InMemoryCache, ApolloClient } from '@apollo/client';
import { ApolloProvider } from '@apollo/react-hooks';
import { globalVariables } from './lib/globalVariables';
import { ga } from './lib/analytics';
import Routes from './routes';

// Styles
import './styles/default.scss';

// Create a history of your choosing (we're using a browser history in this case)
const history = createBrowserHistory();

const client = new ApolloClient({
  uri: globalVariables.getEnvironmentVariables().graphqlURI,
  fetch,
  cache: new InMemoryCache()
});

// Initialise analytics
ga.init();

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
  hydrate(
    <React.StrictMode>
      <ApolloProvider client={client}>
        <Routes history={history} />
      </ApolloProvider>
    </React.StrictMode>,
    rootElement
  );
} else {
  render(
    <React.StrictMode>
      <ApolloProvider client={client}>
        <Routes history={history} />
      </ApolloProvider>
    </React.StrictMode>,
    rootElement
  );
}
