import ganalytics from 'react-ga';

// Google Analytics
const ga = {
    init() {
        // Function to initialize the google analytics with the right gaTrackingID
        ganalytics.initialize('UA-168810341-2');

        // Setup a pageview tracker by default
        ganalytics.pageview(window.location.pathname + window.location.search);
    },
    event(param) {
        ganalytics.event({
            category: param.category,
            action: param.action,
            label: param.label,
            value: param.value
        });
    }
};

export { ga };
