import React from 'react';

// Styles
import './contentSection.scss';

const ContentSeciont = ({
    title,
    content,
    image,
    className,
    contentPosition = 'left'
}) => {
    const flexDirection = contentPosition === 'left' ? 'row' : 'row-reverse';
    return (
        <div
            className={`contentSection ${className ? className : ''}`}
            style={{ flexDirection }}
        >
            <div className="content">
                <h3>{title}</h3>
                <p>{content}</p>
            </div>
            <div className="imgContainer">
                <img src={image} alt="imageSection" />
            </div>
        </div>
    );
};

export default ContentSeciont;
