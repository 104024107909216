import gql from 'graphql-tag';

export default gql`
    query FindBikesBySpecs($gender: String!, $height: Int!, $category: Int!, $avg_usage_time: Float!, $price_range: String, $email: String) {
        findBikesBySpecs(
            gender: $gender,
            height: $height,
            category: $category,
            avg_usage_time: $avg_usage_time,
            price_range: $price_range,
            email: $email
        ) {
            _id
            model
            category
            type
            photos {
                url
            }
            Brand {
                _id
                name
            }
            calculatedPriceRangeDescription
            general_specs {
                max_speed
                min_range
                max_range
                load_capacity
                calculatedPerfectBikeSize {
                    type
                }
                sizes {
                    type
                    weight
                    wheel_size
                    frame_size
                }
            }
            eletric_parts {
                display
                assistance_level
                controller
                charger
                motor
                battery
                lights
            }
            bike_components {
                frame
                handlebar
                fork
                brakes
                crank
                gear_shift
                pinion
                derailleur
                tyres
                saddle
                seat_post
                grip
                pedals
            }
        }
    }
`;
