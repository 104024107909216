import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useQuery, useMutation } from '@apollo/react-hooks';
import BackIcon from '@material-ui/icons/KeyboardBackspace';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { bikeUtils } from '../lib/bikes';
import { scooterUtils } from '../lib/scooters';

// Queries
import findBikesBySpecsQuery from '../data/queries/bikes/findBikesBySpecs';
import findScootersBySpecs from '../data/queries/bikes/findScootersBySpecs';

// Mutations
import createUserMutation from '../data/mutations/createUser';

// External components
import BikeTable from './bikeTable';

// Styles
import './bikesSection.scss';

const BikesSection = (props) => {
    const [expanded, setExpanded] = useState('general');
    const [createUser] = useMutation(createUserMutation);

    useEffect(() => {
        if (_.get(props, 'email')) {
            // Create user from bike form submission
            createUser({
                variables: {
                    user: {
                        email: props.email,
                        utm_source: 'LandingPage_BikeForm'
                    }
                }
            });
        }
    }, [props, createUser]);

    useEffect(() => {
        if (_.get(props, 'data.email')) {
            const element = document.getElementById('top3BikesContainer');
            if (element) element.scrollIntoView({ behavior: 'smooth', inline: 'nearest', block: 'center' });
        }
    }, [_.get(props, 'data')]);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    // Test whether the user is querying a bike or a scooter
    let queryToFetch = findBikesBySpecsQuery;
    let variablesToFetch = {
        gender: _.get(props, 'data.gender'),
        height: parseInt(_.get(props, 'data.height')),
        category: parseInt(_.get(props, 'data.category')),
        avg_usage_time: parseFloat(_.get(props, 'data.avgUsage')),
        price_range: _.get(props, 'data.priceRange'),
        email: _.get(props, 'data.email')
    };
    if (_.get(props, 'data.type') === 1) {
        queryToFetch = findScootersBySpecs;
        variablesToFetch = {
            avg_usage_time: parseFloat(_.get(props, 'data.avgUsage')),
            price_range: _.get(props, 'data.priceRange'),
            email: _.get(props, 'data.email')
        };
    }

    const { data } = useQuery(queryToFetch, {
        variables: variablesToFetch
    });
    const dataSpecs = _.get(props, 'data.type') === 0 ? 'findBikesBySpecs' : 'findScootersBySpecs';
    let findBySpecs = _.get(data, dataSpecs);

    const numberOfBikes = _.size(findBySpecs);
    let containerBikesMeasure = 'quarter';
    if (numberOfBikes > 3) findBySpecs = _.get(data, dataSpecs).slice(0, 3);
    if (numberOfBikes === 2) containerBikesMeasure = 'half';
    if (numberOfBikes === 1) containerBikesMeasure = 'full';

    const renderTop3 = (ride, index) => {
        const rideImg = _.get(ride, 'photos[0].url');
        return (
            <div
                key={_.get(ride, '_id')}
                className={`bikeCard ${index === 0 ? 'bestChoiceBike' : ''}`}
            >
                {index === 0 && (
                    <div className="bestChoiceContainer">
                        <h4>Best Choice</h4>
                    </div>
                )}
                {rideImg && (
                    <div className="imgContainer">
                        <img
                            src={rideImg}
                            alt={_.get(ride, 'model')}
                        />
                    </div>
                )}
                <h3>{_.get(ride, 'model')}</h3>
                {_.get(props, 'data.type') === 0 ? bikeUtils.getBikeDescriptionHtml(ride) : scooterUtils.getScooterDescriptionHtml(ride)}
            </div>
        );
    };

    return (
        <div className="bikesSection">
            <div className="newSearchIcon">
                <a onClick={props.onClickNewSearch}>
                    <>
                        <BackIcon style={{ color: '#00FFB7', width: 36, height: 36 }} />
                        New Search
                    </>
                </a>
            </div>
            {_.size(findBySpecs) === 0 && (
                <span className="noResultsFound">Ooops, our robots 🤖 didn’t find any e-rides with your search.&nbsp;<a onClick={props.onClickNewSearch}>Wanna try again?</a></span>
            )}
            {_.size(findBySpecs) > 0 && (
                <>
                    <div
                        id="top3BikesContainer"
                        className="top3BikesContainer"
                    >
                        {_.map(findBySpecs, renderTop3)}
                    </div>
                    <div className="top3BikesSpecs">
                        <div className="top3BikeNamesContainer">
                            {_.map(findBySpecs, (bike) => {
                                return (
                                    <div
                                        key={bike._id}
                                        className={`bikeName ${containerBikesMeasure}Container`}
                                    >
                                        <h4>{_.get(bike, 'model')}</h4>
                                    </div>
                                );
                            })}
                        </div>
                        <Accordion expanded={expanded === 'general'} onChange={handleChange('general')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon style={{ color: '#00FF64' }} />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <p>General</p>
                            </AccordionSummary>
                            <AccordionDetails>
                                <BikeTable
                                    data={findBySpecs}
                                    type="general"
                                    className={`${containerBikesMeasure}Table`}
                                />
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'eletricParts'} onChange={handleChange('eletricParts')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon style={{ color: '#00FF64' }} />}
                                aria-controls="eletricPartsbh-content"
                                id="eletricPartsbh-header"
                            >
                                <p>Electric Parts</p>
                            </AccordionSummary>
                            <AccordionDetails>
                                <BikeTable
                                    data={findBySpecs}
                                    type="eletricParts"
                                    className={`${containerBikesMeasure}Table`}
                                />
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'bikeComponents'} onChange={handleChange('bikeComponents')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon style={{ color: '#00FF64' }} />}
                                aria-controls="bikeComponentssbh-content"
                                id="bikeComponentssbh-header"
                            >
                                <p>Other</p>
                            </AccordionSummary>
                            <AccordionDetails>
                                <BikeTable
                                    data={findBySpecs}
                                    type={_.get(props, 'data.type') === 0 ? 'bikeComponents' : 'escooterComponents'}
                                    className={`${containerBikesMeasure}Table`}
                                />
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </>
            )}
        </div>
    );
};

export default BikesSection;
