import gql from 'graphql-tag';

export default gql`
    query FindScootersBySpecs($avg_usage_time: Float!, $price_range: String, $email: String) {
        findScootersBySpecs(
            avg_usage_time: $avg_usage_time,
            price_range: $price_range,
            email: $email
        ) {
            _id
            model
            type
            photos {
                url
            }
            Brand {
                _id
                name
            }
            calculatedPriceRangeDescription
            general_specs {
                max_speed
                max_range
                load_capacity
                max_hill_climb
                ground_clearance
                ip_rating
                sizes {
                    weight
                    dimensions
                    dimensions_folded
                }
            }
            eletric_parts {
                battery
                motor
                display
                charge_time
                lights
            }
            escooter_components {
                bar_width
                suspension
                brakes
                tyres
                other_specs
            }
        }
    }
`;
