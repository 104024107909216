import React from 'react';
import _ from 'lodash';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { bikeUtils } from '../lib/bikes';

const BikeTable = ({ data, type, className = '' }) => {
    let rows = [];
    // Check if it's bike or scooter
    if (_.get(data, '[0].type') === 0) {
        switch (type) {
            case 'general':
                rows = [
                    { spec: 'Category', bikeData: bikeUtils.getData(data, 'category') },
                    { spec: 'Brand', bikeData: bikeUtils.getData(data, 'brand') },
                    { spec: 'Price Range', bikeData: bikeUtils.getData(data, 'calculatedPriceRangeDescription') },
                    { spec: 'Perfect Fit Size', bikeData: bikeUtils.getData(data, 'general_specs.calculatedPerfectBikeSize.type'), className: 'toCapitalize' },
                    { spec: 'Max Speed', bikeData: bikeUtils.getData(data, 'general_specs.max_speed') },
                    { spec: 'Range', bikeData: bikeUtils.getData(data, 'general_specs.min_range', 'general_specs.max_range') },
                    { spec: 'Capacity', bikeData: bikeUtils.getData(data, 'general_specs.load_capacity') },
                    { spec: 'Weight', bikeData: bikeUtils.getData(data, 'weight') },
                    { spec: 'Wheel Size', bikeData: bikeUtils.getData(data, 'wheel_size') },
                    { spec: 'Frame Size', bikeData: bikeUtils.getData(data, 'frame_size') },
                ];
                break;
            case 'eletricParts':
                rows = [
                    { spec: 'Battery', bikeData: bikeUtils.getData(data, 'eletric_parts.battery') },
                    { spec: 'Motor', bikeData: bikeUtils.getData(data, 'eletric_parts.motor') },
                    { spec: 'Display', bikeData: bikeUtils.getData(data, 'eletric_parts.display') },
                    { spec: 'Assistance Level', bikeData: bikeUtils.getData(data, 'eletric_parts.assistance_level') },
                    { spec: 'Charger', bikeData: bikeUtils.getData(data, 'eletric_parts.charger') },
                    { spec: 'Controller', bikeData: bikeUtils.getData(data, 'eletric_parts.controller') },
                    { spec: 'Lights', bikeData: bikeUtils.getData(data, 'eletric_parts.lights') },
                ];
                break;
            case 'bikeComponents':
                rows = [
                    { spec: 'Frame', bikeData: bikeUtils.getData(data, 'bike_components.frame') },
                    { spec: 'Handlebar', bikeData: bikeUtils.getData(data, 'bike_components.handlebar') },
                    { spec: 'Fork', bikeData: bikeUtils.getData(data, 'bike_components.fork') },
                    { spec: 'Brakes', bikeData: bikeUtils.getData(data, 'bike_components.brakes') },
                    { spec: 'Crank', bikeData: bikeUtils.getData(data, 'bike_components.crank') },
                    { spec: 'Gear Shift', bikeData: bikeUtils.getData(data, 'bike_components.gear_shift') },
                    { spec: 'Pinion', bikeData: bikeUtils.getData(data, 'bike_components.pinion') },
                    { spec: 'Derailleur', bikeData: bikeUtils.getData(data, 'bike_components.derailleur') },
                    { spec: 'Tyres', bikeData: bikeUtils.getData(data, 'bike_components.tyres') },
                    { spec: 'Saddle', bikeData: bikeUtils.getData(data, 'bike_components.saddle') },
                    { spec: 'Seat Post', bikeData: bikeUtils.getData(data, 'bike_components.seat_post') },
                    { spec: 'Grip', bikeData: bikeUtils.getData(data, 'bike_components.grip') },
                    { spec: 'Pedals', bikeData: bikeUtils.getData(data, 'bike_components.pedals') },
                ];
                break;
            default:
                break;
        }
    } else {
        switch (type) {
            case 'general':
                rows = [
                    { spec: 'Brand', bikeData: bikeUtils.getData(data, 'brand') },
                    { spec: 'Price Range', bikeData: bikeUtils.getData(data, 'calculatedPriceRangeDescription') },
                    { spec: 'Max Speed', bikeData: bikeUtils.getData(data, 'general_specs.max_speed') },
                    { spec: 'Range', bikeData: bikeUtils.getData(data, 'general_specs.max_range') },
                    { spec: 'Capacity', bikeData: bikeUtils.getData(data, 'general_specs.load_capacity') },
                    { spec: 'Weight', bikeData: bikeUtils.getData(data, 'weight') },
                    { spec: 'Max Hill Climb', bikeData: bikeUtils.getData(data, 'general_specs.max_hill_climb') },
                    { spec: 'Ground Clearance', bikeData: bikeUtils.getData(data, 'general_specs.ground_clearance') },
                    { spec: 'IP Rating', bikeData: bikeUtils.getData(data, 'general_specs.ip_rating') },
                    { spec: 'Dimensions', bikeData: bikeUtils.getData(data, 'dimensions') },
                    { spec: 'Dimensions Folded', bikeData: bikeUtils.getData(data, 'dimensions_folded') },
                ];
                break;
            case 'eletricParts':
                rows = [
                    { spec: 'Battery', bikeData: bikeUtils.getData(data, 'eletric_parts.battery') },
                    { spec: 'Motor', bikeData: bikeUtils.getData(data, 'eletric_parts.motor') },
                    { spec: 'Display', bikeData: bikeUtils.getData(data, 'eletric_parts.display') },
                    { spec: 'Charge Time', bikeData: bikeUtils.getData(data, 'eletric_parts.charge_time') },
                    { spec: 'Lights', bikeData: bikeUtils.getData(data, 'eletric_parts.lights') },
                ];
                break;
            case 'escooterComponents':
                rows = [
                    { spec: 'Bar Width', bikeData: bikeUtils.getData(data, 'escooter_components.bar_width') },
                    { spec: 'Suspension', bikeData: bikeUtils.getData(data, 'escooter_components.suspension') },
                    { spec: 'Brakes', bikeData: bikeUtils.getData(data, 'escooter_components.brakes') },
                    { spec: 'Tyres', bikeData: bikeUtils.getData(data, 'escooter_components.tyres') },
                    { spec: 'Other Specs', bikeData: bikeUtils.getData(data, 'escooter_components.other_specs') },
                ];
                break;
            default:
                break;
        }
    }

    return (
        <div className={`bikeTableComponent ${className}`}>
            <TableContainer component={Paper}>
                <Table className="tableComponent" aria-label="simple table">
                    <TableBody>
                        {rows.map((row, index) => (
                            <TableRow key={`${row.spec}_${index}`}>
                                <TableCell component="th" scope="row">{row.spec}</TableCell>
                                {_.map(row.bikeData, (bikeData, indexBikeData) => (
                                    <TableCell
                                        key={`${bikeData}_${indexBikeData}`}
                                        className={row.className && row.className}
                                    >
                                        {bikeData}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default BikeTable;
