import React from 'react';
import _ from 'lodash';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { useQuery } from '@apollo/react-hooks';
import { ga } from '../lib/analytics';
import { utils } from '../lib/utils';

// Queries
import getClients from '../data/queries/clients/getClients';

// Styles
import './partnersSection.scss';

const PartnersSection = () => {
    const { data } = useQuery(getClients);

    const renderClient = (client) => {
        const locations = _.get(client, 'location');
        const locationDescription = _.map(locations, (location) => location.name).toString().replace(/,/g, ' | ');
        const link = utils.getLink({ website: _.get(client, 'website'), campaign: 'bike_search' });
        return (
            <Paper
                key={client._id}
                elevation={3}
                className="clientCard"
            >
                {_.has(client, 'logo') && (
                    <a
                        href={link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="clientLogo"
                        onClick={() => {
                            ga.event({
                                category: 'Bike shops',
                                action: 'Clicked on bike shop logo',
                                label: _.get(client, 'name')
                            });
                        }}
                    >
                        <img src={client.logo} alt="client Logo" />
                    </a>
                )}
                <div className="contentCard">
                    <h4>{_.get(client, 'name')}</h4>
                    {locationDescription && (
                        <div className="locationsRow">
                            <span>{locationDescription}</span>
                        </div>
                    )}
                </div>
                {_.has(client, 'website') && (
                    <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        className="filledButton"
                        style={{ borderRadius: 30, padding: '10px 35px', textTransform: 'none' }}
                        href={link}
                        target="_blank"
                        onClick={() => {
                            ga.event({
                                category: 'Bike shops',
                                action: 'Clicked on Go to site',
                                label: _.get(client, 'name')
                            });
                        }}
                    >
                        Go to site
                    </Button>
                )}
            </Paper>
        );
    };

    return (
        <div
            className="partnersSectionComponent"
        >
            <h2>Bike shops</h2>
            <div className="clientsContainer">
                {_.map(_.get(data, 'getClients'), renderClient)}
            </div>
        </div>
    );
};

export default PartnersSection;
