import React from 'react';
import { Router, Route, Switch } from 'react-router';
import { createMuiTheme, ThemeProvider, responsiveFontSizes } from '@material-ui/core/styles';

// External components
import Homepage from './homepage/homepage';
import Privacy from './privacy';

let theme = createMuiTheme({
    palette: {
        primary: {
            main: '#242424'
        },
        secondary: {
            main: '#00ff64',
            text: '#fff'
        },
    },
});
theme = responsiveFontSizes(theme);

const Routes = (props) => {
    return (
        <Router {...props}>
            <ThemeProvider theme={theme}>
                <Switch>
                    <Route exact path="/privacy" component={Privacy} />
                    <Route path="/" component={Homepage} />
                </Switch>
            </ThemeProvider>
        </Router>
    );
};

export default Routes;
