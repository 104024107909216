import gql from 'graphql-tag';

export default gql`
    query GetBikeBrands {
        getBikeBrands {
            _id
            name
            logo
            website
        }
    }
`;
