import React from 'react';
import _ from 'lodash';
import InfoIcon from '@material-ui/icons/Info';

const bikeUtils = {
    renderRangeRow(bike) {
        if (_.get(bike, 'general_specs.min_range')) {
            return (
                <>
                    <strong>Range:</strong>&nbsp;<span>{bikeUtils.getRangeDescription(_.get(bike, 'general_specs.min_range'))} to {bikeUtils.getRangeDescription(_.get(bike, 'general_specs.max_range'))}</span><div className="infoIconContainer"><InfoIcon className="infoIcon" /><p className="tooltiptext">Depending on assistance level</p></div>
                </>
            );
        }
        return (
            <>
                <strong>Range:</strong>&nbsp;<span>Up to {bikeUtils.getRangeDescription(_.get(bike, 'general_specs.max_range'))}</span><div className="infoIconContainer"><InfoIcon className="infoIcon" /><p className="tooltiptext">Depending on assistance level</p></div>
            </>
        );
    },
    getPriceRangeDescriptionBasedOnAvgAmount(avg_amount) {
        if (avg_amount < 2000) return 'Basic';
        if (avg_amount >= 2000 && avg_amount < 3500) return 'Intermediate';
        if (avg_amount > 3500) return 'Premium';
        return '';
    },
    getBikeDescriptionHtml(bike) {
        return (
            <div className="descriptionContainer">
                <div className="descriptionRow">
                    <strong>Category:</strong>&nbsp;<span>{bikeUtils.getCategoryDescription(_.get(bike, 'category'))}</span>
                </div>
                <div className="descriptionRow">
                    <strong>Brand:</strong>&nbsp;<span>{_.get(bike, 'Brand.name')}</span>
                </div>
                <div className="descriptionRow">
                    <strong>Sizes:</strong>&nbsp;<span>{bikeUtils.getAvailableSizesDescription(bike)}</span>
                </div>
                <div className="descriptionRow">
                    {bikeUtils.renderRangeRow(bike)}
                </div>
                <div className="descriptionRow">
                    <strong>Price range:</strong>&nbsp;<span>{_.get(bike, 'calculatedPriceRangeDescription')}</span>
                </div>
            </div>
        );
    },
    getCategoryDescription(category) {
        switch (category) {
            case 0:
                return 'Mountain Bike';
            case 1:
                return 'City / Road Bike';
            case 2:
                return 'Trekking / Hybrid Bike';
            default:
                break;
        }
    },
    getAvailableSizesDescription(data) {
        const bikeSizes = _.map(_.get(data, 'general_specs.sizes'), (bike, index) => {
            const type = _.get(bike, 'type');
            return `${index !== 0 ? ' ' : ''}${type.charAt(0).toUpperCase()}${type.slice(1)}`;
        });
        return bikeSizes.toString();
    },
    getBikeSizesField(data) {
        const bikeSizes = _.get(data, 'general_specs.sizes');
        const perfectBikeSize = _.get(data, 'general_specs.calculatedPerfectBikeSize.type');
        return _.find(bikeSizes, (item) => item.type === perfectBikeSize);
    },
    getMaxSpeedDescription(maxSpeed) {
        if (!maxSpeed) return '-';
        return `${maxSpeed}km/h`;
    },
    getRangeDescription(range) {
        if (!range) return '-';
        return `${range}km`;
    },
    getLoadCapacityDescription(capacity) {
        if (!capacity) return '-';
        return `Max ${capacity}kg`;
    },
    getBikeWeightDescription(weight) {
        if (!weight) return '-';
        return `${weight}kg`;
    },
    getWheelSizeDescription(wheel_size) {
        if (!wheel_size) return '-';
        return `${wheel_size}"`;
    },
    getFrameSizeDescription(frame_size) {
        if (!frame_size) return '-';
        return `${frame_size}"`;
    },
    getDimensionsDescription(dimensions) {
        if (!dimensions) return '-';
        return `${dimensions}"`;
    },
    getDimensionsFoldedDescription(dimensions_folded) {
        if (!dimensions_folded) return '-';
        return `${dimensions_folded}"`;
    },
    getFieldData(data, field) {
        const bikeSizeFields = bikeUtils.getBikeSizesField(data);
        switch (field) {
            case 'category':
                return bikeUtils.getCategoryDescription(_.get(data, field));
            case 'brand':
                return _.get(data, 'Brand.name');
            case 'general_specs.max_speed':
                return bikeUtils.getMaxSpeedDescription(_.get(data, field));
            case 'general_specs.min_range':
            case 'general_specs.max_range':
                return bikeUtils.getRangeDescription(_.get(data, field));
            case 'general_specs.load_capacity':
                return bikeUtils.getLoadCapacityDescription(_.get(data, field));
            case 'weight':
                return bikeUtils.getBikeWeightDescription(_.get(bikeSizeFields, field));
            case 'wheel_size':
                return bikeUtils.getWheelSizeDescription(_.get(bikeSizeFields, field));
            case 'frame_size':
                return bikeUtils.getFrameSizeDescription(_.get(bikeSizeFields, field));
            case 'dimensions':
                return bikeUtils.getDimensionsDescription(_.get(bikeSizeFields, field));
            case 'dimensions_folded':
                return bikeUtils.getDimensionsFoldedDescription(_.get(bikeSizeFields, field));
            default:
                const defaultData = _.get(data, field);
                if (!defaultData) return '-';
                return defaultData;
        }
    },
    getData(dataset, field, field_2) {
        return _.map(dataset, (item) => {
            if (field_2) {
                return `${bikeUtils.getFieldData(item, field) || 0} - ${bikeUtils.getFieldData(item, field_2)}`;
            }
            return bikeUtils.getFieldData(item, field);
        });
    }
};

export { bikeUtils };
